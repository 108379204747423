<template>
    <div class="pageContainer">
        <a-row class="invoiceRecord">
            <a-row class="titleRow">
                <span class="longString"></span>
                发票记录
            </a-row>
            <a-table :columns="columns" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }">
                <a slot="action" slot-scope="text, record" @click="detail(record)">详情</a>
            </a-table>

            <NotarizeInvoice :visible="visible" :closeModal="closeModal" :invoiceData="invoiceData" />
        </a-row>
    </div>
</template>
<script>
import NotarizeInvoice from "./components/NotarizeInvoice";
export default {
    components: {
        NotarizeInvoice,
    },
    data() {
        const _this = this;
        return {
            visible: false,
            invoiceData: {},
            columns: [
                {
                    title: "时间",
                    dataIndex: "addTime",
                    key: "addTime",
                    customRender(text) {
                        return _this.$moment(text).format("YYYY-MM-DD HH:mm:ss");
                    },
                },
                {
                    title: "金额",
                    dataIndex: "amount",
                    key: "amount",
                },
                {
                    title: "发票类型",
                    dataIndex: "type",
                    key: "type",
                    customRender(text) {
                        return "电子发票";
                    },
                },
                {
                    title: "是否开票",
                    dataIndex: "state",
                    key: "state",
                    customRender(text) {
                        return text == 0 ? "待开票" : text == 1 ? "已开票" : text == 2 ? "开票失败" : "--";
                    },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            data: [
                {
                    key: 1,
                    addTime: "2020.02.13",
                    money: "134.02",
                    type: 1,
                    invoice: 1,
                },
            ],
            pagination: {
                total: 0,
                current: 0,
                defaultPageSize: 10,
                showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "15", "20"],
                onShowSizeChange: (current, pageSize) => {
                    this.pageSize = pageSize;
                },
            },
            pageSize: 10,
            tableHeight: 450,
        };
    },
    created() {
        this.invoiceList(0, 10);

        if (document.body.clientWidth <= 1366) {
            this.tableHeight = 390;
        } else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
            this.tableHeight = 500;
        } else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
            this.tableHeight = 530;
        } else if (document.body.clientWidth > 1600) {
            this.tableHeight = 650;
        }
    },
    methods: {
        closeModal() {
            this.visible = false;
        },
        detail(record) {
            this.visible = true;
            this.invoiceData = record;
        },
        tableChange(e) {
            this.invoiceList(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        invoiceList(pageNum, pageSize) {
            let that = this;
            let params = {
                pageNum: pageNum || pageNum == 0 ? pageNum : 0,
                pageSize: pageSize ? pageSize : that.pageSize ? that.pageSize : 10,
                userCode: this.$store.getters.userInfo.userCode,
            };
            this.$http
                .post("/accttax/queryPageList", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.data = res.data.dataList;
                        this.pagination.total = res.data.totalCount;
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .invoiceRecord {
        background: #fff;
        height: 100%;
    }
    .titleRow {
        margin-top: 30px;
        font-size: 20px;
        position: relative;
        margin-left: 20px;
        .longString {
            width: 3px;
            height: 25px;
            background-color: #2d7bf8;
            position: absolute;
            top: -3px;
            left: -10px;
        }
    }
    .table {
        margin-top: 20px;
    }
}
</style>
