<template>
    <a-modal class="illegalAdSubModal" title="发票信息" :maskClosable="false" okText="提交" cancelText="关闭" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <template v-if="invoiceData.objectType == 1">
            <a-row class="row"> 公司名称：{{ invoiceData.name || "--" }} </a-row>
            <a-row class="row"> 公司税号：{{ invoiceData.certificateNo || "--" }} </a-row>
            <a-row class="row">
                开票金额：<span class="red">{{ invoiceData.amount || "--" }}</span
                >元
            </a-row>
            <a-row class="row"> 开户名：{{ invoiceData.bankAcount || "--" }} </a-row>
            <a-row class="row"> 开户行：{{ invoiceData.bankName || "--" }} </a-row>
            <a-row class="row">
                包含订单：<span class="red">{{ invoiceData.tradeCount || "--" }}</span>
            </a-row>
            <a-row class="row"> 联系方式：{{ invoiceData.userPhone || "--" }} </a-row>
            <a-row class="row"> 电子邮箱：{{ invoiceData.email || "--" }} </a-row>
            <a-row class="row"> 申请时间：{{ moment(invoiceData.addTime) }} </a-row>
        </template>
        <template v-if="invoiceData.objectType == 2">
            <a-row class="row"> 身份证：{{ invoiceData.idCard || "--" }} </a-row>
            <a-row class="row">
                开票金额：<span class="red">{{ invoiceData.amount || "--" }}</span
                >元
            </a-row>
            <a-row class="row">
                包含订单：<span class="red">{{ invoiceData.tradeCount || "--" }}</span>
            </a-row>
            <a-row class="row"> 联系方式：{{ invoiceData.userPhone || "--" }} </a-row>
            <a-row class="row"> 电子邮箱：{{ invoiceData.email || "--" }} </a-row>
            <a-row class="row"> 申请时间：{{ moment(invoiceData.addTime) }} </a-row>
        </template>
    </a-modal>
</template>

<script>
export default {
    props: ["visible", "closeModal", "invoiceData"],
    data() {
        return {
            confirmLoading: false,
            phone: null,
            password: null,
            password2: null,
        };
    },
    methods: {
        moment(data) {
            return this.$moment(data).format("YYYY-MM-DD HH:mm:ss");
        },
        handleCancel() {
            this.closeModal();
        },
        handleOk() {
            this.confirmLoading = true;
            setTimeout(() => {
                this.closeModal();
                this.confirmLoading = false;
                this.$router.push({
                    name: "FinancialManagement",
                    query: {},
                });
            }, 2000);
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    /deep/ .userPhoneSmsInput {
        width: 78%;
    }
    .row {
        margin-top: 10px;
        margin-left: 20px;
        font-size: 16px;
        .red {
            color: red;
            font-size: 20px;
        }
    }
}
</style>
